import React from "react"
import PrivacyText from "./privacy-policy-text"
import "./privacy-policy-popup.css"
// import close from "../../content/assets/img/icon-close.svg"
import Popup from "./popup"


function PrivacyPopup(props){
    return(
        <Popup onClose={props.onClose} noButton={true} className="bot-popup--privacy">
            <PrivacyText page={props.privacy} settings={props.settings}></PrivacyText>
        </Popup>
    )
}

export default PrivacyPopup