import React, { useState } from "react"

import GoTriangleDown from "../../content/assets/img/icon-select.svg"
// import { navigate } from 'gatsby-link'
import { formEncode } from '../utils/utils'
import PrivacyPopup from "./privacy-policy-popup";
import close from "../../content/assets/img/icon-close.svg"
import Popup from "./popup";
import { Grid, TextField, Button, IconButton, Checkbox} from '@material-ui/core';
import {Select, FormControl, InputLabel, MenuItem, FormControlLabel} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';

function JobForm(props) {
    // const translation = {props.translation}
    // console.log(props.translation);

    const[botForm, setBotForm] = useState({
        fname: "",
        lname: "",
        email: "",
        phone: "",
        in: "",
        find: "",
        privacy: false,
        message:"",
        attachment: {}
    });
    function handleChange(e){
        const newValue = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const fieldName = e.target.name;
        const newBotForm = {...botForm};
        newBotForm[fieldName] = newValue;
        // console.log(newBotForm);
        setBotForm(newBotForm);
    }

    const handleAttachment = (e) => {
        if (e.target.files && e.target.files[0] && e.target.files[0].size > 15000000) {
            alert("File size should be under 15mb");
            return;
        } else if (!e.target.files[0]) {
            return;
        }
        const newBotForm = { ...botForm, [e.target.name]: e.target.files[0] };
        // console.log(newBotForm);
        setBotForm(newBotForm);
    }

    function handleSubmit(e){
        e.preventDefault();
        // console.warn(e.target.name, botForm);
    
        const form = e.target;
        fetch('/', {
          method: 'POST',
          body: formEncode({
            'form-name': form.getAttribute('name'),
            ...botForm,
          }),
        })
          .then(() => {
            //   navigate(form.getAttribute('action'))
            setSuccessPopUpVisible(true)
            })
            .catch((error) => {
                // console.error(error);
            });
    }
    
    let [popUpVisible, setPopUpVisible] = useState(false);
    let [successPopUpVisible, setSuccessPopUpVisible] = useState(false);


    return(
    <div className="bot-form mb60">
        <div className="bot-form_in">
            <form method="post" name="job" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={handleSubmit}>
            <input type="hidden" name="form-name" value="job" />
            <Grid container spacing={2} className="bot-fields-container">
                <Grid item xs={12}>
                    <TextField className="bot-field-short" variant="filled" type="text" name="fname" id="name" title="First name" label={props.translation.formJob.firstName} value={botForm.fname} onChange={handleChange} required fullWidth></TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField className="bot-field-short" variant="filled" type="text" name="lname" id="lastname" title="Last name" label={props.translation.formJob.lastName} value={botForm.lname} onChange={handleChange} fullWidth></TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField className="bot-field-short" variant="filled" type="email" name="email" id="email" title="Email address" label={props.translation.formJob.email} value={botForm.email} onChange={handleChange} required fullWidth></TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField className="bot-field-short" variant="filled" type="tel" name="phone" id="phone" title="Phone number" label={props.translation.formJob.phone} maxLength="13" value={botForm.phone} onChange={handleChange} fullWidth></TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField className="bot-field-short" variant="filled" type="text" name="in" id="linkedin" title="Linkedin profile" label={props.translation.formJob.linkedin} value={botForm.in} onChange={handleChange} fullWidth></TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField className="bot-field-short" variant="filled" type="text" fullWidth disabled
                        value={botForm.attachment.name ? botForm.attachment.name : props.translation.formJob.upload}
                    ></TextField>
                    <IconButton aria-label="upload" color="primary">
                        <PublishIcon fontSize="large" color="primary"/><input type="file" name="attachment" id="bot-form-file" accept=".pdf" hidden onChange={handleAttachment}/>
                    </IconButton>
                </Grid>
                <Grid item xs={12}>
                    <TextField className="bot-field-ta" variant="filled" name="message" id="message" title="Your message" rows="5" value={botForm.message} onChange={handleChange} label={props.translation.formJob.message} required fullWidth multiline></TextField>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="filled" style={{minWidth: 250}}>
                        <InputLabel id="find-label">{props.translation.formJob.findLabel}</InputLabel>
                        <Select labelId="find-label" name="find" value={botForm.find} onChange={handleChange}>
                            {
                            props.translation.formJob.find.map((item)=>{
                                return(
                                    <MenuItem key={item.name} value={item.name}>{item.name}</MenuItem>
                                )
                            })
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset">
                        <FormControlLabel
                            control={<Checkbox color="primary" id="agree" name="agree" required/>}
                            label={<span>{props.translation.formJob.accept} <button className="bot-text-link fwb bot-form-agree-text" type="button" onClick={()=>setPopUpVisible(true)}>{props.translation.formJob.privacy}</button></span>}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}><Button variant="contained" color="primary" type="submit">{props.translation.formJob.applyNow}</Button></Grid>
            </Grid>
            </form>
        </div>
        {
           popUpVisible && <PrivacyPopup privacy={props.privacy} onClose={()=>setPopUpVisible(false)} settings={props.settings}></PrivacyPopup>
        }
        {
           successPopUpVisible && 
           <Popup 
           buttonText="Close" 
           noClose={true} 
           onClose={()=>setSuccessPopUpVisible(false)} 
           translation={props.translation}
           >
               {props.translation.popUpJob.yourApplication}
            </Popup>
        }   
    </div>
    )
}

export default JobForm