import React from "react"
import { graphql } from 'gatsby'
import Footer from "../components/footer"
import SEO from "../components/seo"
import TopMenu from "../components/topmenu"
import JobPosition from "../components/job-position"
import CookiesPopup from "../components/cookies-popup"

const JobPageTemplate = ({ data, pageContext }) => {

  const slug = data.page.fields.slug;
  const job = data.page.frontmatter;
  const privacy = data.privacy.frontmatter;

  return (
    <section className="bot-page bg-green-light">
      <SEO
        title={job.title + " - Open position"}
        description={job.metadescription}
        keywords={[`Embedded`, `Sulautetut`, `sulautettu`, `Tickingbot`, `software`, `consultant`, `ohjelmistokonsultti`, `Test automation`, `testiautomaatio`, `testausautomaatio`]}
        />
      <TopMenu 
      language={pageContext.language} 
      languages={pageContext.languages} 
      slug={slug} 
      translation={pageContext.translation}
      jobs={data.index.frontmatter.jobs}
      listJobs={data.jobs.nodes}
      services={data.index.frontmatter.services.list}
      settings={data.settings.childMdx.frontmatter}
      ></TopMenu>
      
      <JobPosition 
      info={job} 
      privacy={privacy} 
      translation={pageContext.translation} 
      settings={data.settings.childMdx.frontmatter}
      ></JobPosition>
       
      <Footer settings={data.settings.childMdx.frontmatter}></Footer>
      {
      <CookiesPopup translation={pageContext.translation}></CookiesPopup>
      }
    </section>
     
  )
}

export const pageQuery = graphql`
  query JobById($id: String!, $language: String!) {
    page: mdx( id: { eq: $id }) {
      id
      slug
      fields{
        slug
      }
      frontmatter {
        title
        date(formatString: "DD.MM.YYYY", locale: $language)
        metadescription
        shortdescription
        description
        location
        relationship
        contact
      }
    }
    privacy: mdx(frontmatter: {templateKey: {eq: "templates/privacy"}, language: {eq: $language}}) {
      frontmatter {
        language
        title
        description
      }
    }
    jobs: allMdx(filter: {frontmatter: {contentType: {eq: "job"}, language: {eq: $language}}}) {
      nodes {
        fields{
          slug
        }
        frontmatter {
          title
          slug
          enabled
        }
      }
    }
    index: mdx(frontmatter: {templateKey: {eq: "templates/index"}, language: {eq: $language}}) {
      frontmatter {
        services {
          list: services {
            title
          }
        }
        jobs {
          list{
            position
          }
        }
      }
    }
    settings: file(sourceInstanceName: {eq: "settings"}, name: {eq: "settings"}) {
      childMdx {
        frontmatter {
          companyname
          sitename
          address
          address2
          phone
          linkedin
          email
        }
      }
    }
  }
`

export default JobPageTemplate
